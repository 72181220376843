jQuery( document ).ready(function($) {

  // mobile menu
  $('#menu-open').click(function(){
    $('.menu-container').addClass('active');
  })

  $('#menu-close').click(function(){
    $('.menu-container').removeClass('active');
  })

  $('.menu-item-has-children').append('<span class="sub-menu-toggle"><i class="fas fa-chevron-down"></i></span>');

  $('.sub-menu-toggle').click(function(){
    $(this).toggleClass('active');
    $(this).siblings('.sub-menu').slideToggle();
  })

  //images slider
  const swiper = new Swiper('.slider-images', {
    navigation: {
      nextEl: '.slider-nav-next',
      prevEl: '.slider-nav-prev',
    },
    autoplay: {
      delay: 2000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
  });

  const swiper2 = new Swiper('.slider-releases', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      300: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      900: {
        slidesPerView: 6,
        spaceBetween: 30,
      },
    }
  });

  const swiper3 = new Swiper('.slider-events', {
    slidesPerView: 4,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  const swiper4 = new Swiper('.slider-videos', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      300: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    }
  });

  const swiper5 = new Swiper('.slider-playlists', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      300: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1000: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    }
  });

  const swiper6 = new Swiper('.slider-artist', {
    slidesPerView: 1,
    spaceBetween: 30,
    grid: {
      fill: 'row',
      rows: 2,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      400: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
      1000: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
    }
  });

  const swiper7 = new Swiper('.slider-releases-by-cat', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      300: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      900: {
        slidesPerView: 6,
        spaceBetween: 30,
      },
    }
  });
  var menu = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13']
  const swiper8 = new Swiper('.slider-artist-playlists', {
    slidesPerView: 1,
    pagination: {
      el: '.slider-pagination',
      clickable: true,
      renderBullet : function (index, className) {
          return '<button class="' + className + '">' + (menu[index]) + '</button>';
      },
    },
    breakpoints: {
      900: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    }
  });

  // featured-video
  $('.play-featured-video').click(function(){
    $(this).hide()
    $(this).siblings('img').hide()
    $(this).siblings('video').get(0).play()
  })

  // videos popup
  $('.video-item a').click(function(e){
    e.preventDefault()
    const videoType = $(this).data('video-type')
    const videoDescription = $(this).data('video-description')
    console.log(videoDescription)
    $('#video-description').html(videoDescription)
    if(videoType === 'embed'){
      const videoEmbed = $(this).find('#video-embed-content').html()
      $('#video-embed-content iframe').attr('aria-hidden', 'true')
      $('#video-embed').html(videoEmbed)
      $('#video-player').hide()
      $('.video-popup').addClass('active')
    } else{
      const videoUrl = $(this).attr('href')
      $('#video-embed').hide()
      $('#video-player source').attr('src', videoUrl)
      $('#video-player').get(0).load()
      $('#video-player').get(0).play()
    }
  })

  $('#video-close').click(function(){
    $('.video-popup').removeClass('active')
    $('#video-embed').html('')
    $('#video-player').get(0).pause()
  })

});